/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useMemo, useRef, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useTour } from "../tourController/tourController";
import * as THREE from "three";

export default function Model(props) {
  const group = useRef();
  const { animations, scene } = useGLTF("/kaffeemaschine6.glb");
  const { actions } = useAnimations(animations, group);
  console.log(actions);
  let { step } = useTour();
  const data = useMemo(
    () => ({
      blinking: [],
      open: [],
    }),
    []
  );
  useEffect(() => {
    data.blinking.forEach((a) => {
      actions && actions[a] && actions[a].stop && actions[a].stop();
    });
    data.open
      .slice()
      .reverse()
      .forEach((a, i) => {
        console.log(a, i);
        if (!step.animations || !step.animations.open.includes(a)) {
          console.log("back?");

          //play backwards?
          let action = actions[a];
          action.timeScale = -1;
          action.clampWhenFinished = true;
          action.setLoop(THREE.LoopOnce);
          setTimeout(() => {
            action.paused = false;
            action.play();
          }, 1000 * i);
        }
      });
    if (step && step.animations) {
      step.animations.blink.forEach((a) => actions[a].play());

      data.blinking = step.animations.blink;

      //play any forward animations once
      step.animations.open.forEach((a) => {
        let action = actions[a];
        action.setLoop(THREE.LoopOnce);
        action.paused = false;
        action.timeScale = 1;
        action.clampWhenFinished = true;
        action.play();
        data.open.push(a);
      });

      data.open = [...step.animations.open];
    }

    return () => {};
    //stop all blinking animations no longer needed:
  }, [step, data, actions]);
  return <primitive ref={group} object={scene} {...props}></primitive>;
}

//useGLTF.preload("/maschine3.glb");
