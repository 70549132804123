import { Canvas, useThree } from "@react-three/fiber";
import React, { Suspense, useEffect } from "react";
import "./factoryCanvas.scss";
import K from "../gltf/Maschine";
import Controller from "./controller";
import useTourState from "../../state/tour";

export default function FactoryCanvas() {
  return (
    <div className="canvasWrapper">
      <div className="title">
        <div>
          <div className="logo">
            <img src="/logo.png" alt="tchibo logo"></img>
          </div>
        </div>
      </div>
      <Canvas className="canvas" camera={{ fov: 55 }}>
        <Things></Things>
      </Canvas>
    </div>
  );
}

function Things() {
  let { gl } = useThree();
  useEffect(() => {
    gl.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  }, [gl]);
  let { selectedTask, selectedStep, tasks } = useTourState();
  let task = null;
  if (selectedTask !== -1) {
    task = tasks[selectedTask];
  }
  let step = {};
  if (task && selectedStep !== -1) {
    step = task.steps[selectedStep];
  }

  return (
    <Suspense fallback={null}>
      <K position={[-2, -1.5, 1]} scale={10}></K>
      <Lights></Lights>
      
      <Controller
        targetPhi={step.phi}
        targetTheta={step.theta}
        target={step.target}
        zoom={step.zoom}
        debug={false}
      ></Controller>
    </Suspense>
  );
}

function Lights() {
  return (
    <>
      <ambientLight intensity={1}></ambientLight>
      <directionalLight
        intensity={1}
        position={[-30, 5, 30]}
      ></directionalLight>
      <directionalLight
        intensity={1}
        position={[-30, 5, -30]}
      ></directionalLight>
      <directionalLight
        intensity={2}
        position={[20, 10, 30]}
      ></directionalLight>
    </>
  );
}
