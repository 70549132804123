import React, { Suspense } from "react";
import "./App.scss";
import { Router } from "@reach/router";

import Tour from "./screens/tour";
import ReactGA from "react-ga";


ReactGA.initialize("UA-200770762-1");
ReactGA.pageview(window.location.pathname+ window.location.search);
//2

function App() {
  return (
    <Suspense fallback={null}>
      <Router>
        <Tour path="/"></Tour>
      </Router>
    </Suspense>
  );
}

export default App;