import React from "react";
import "./tourController.scss";
import {
  FaCheck,
  FaChevronLeft,
  FaChevronRight,
  FaTimes,
} from "react-icons/fa";
import Spacer from "../spacer/spacer";
import useTourState from "../../state/tour";

import ReactGA from "react-ga";

export function useTour() {
  let { selectedTask, selectedStep, tasks, setSelectedStep, setSelectedTask } =
    useTourState();
  console.log({ selectedStep, selectedTask, tasks });
  let task = null;
  if (selectedTask !== -1) {
    task = tasks[selectedTask];
  }
  let step = {};
  if (task && selectedStep !== -1) {
    step = task.steps[selectedStep];
  }

  return {
    selectedTask,
    selectedStep,
    tasks,
    step,
    task,
    setSelectedStep,
    setSelectedTask,
  };
}

export default function TourController() {
  let { tasks, step, task, setSelectedTask, setSelectedStep } = useTour();
  console.log({ task, step });

  return (
    <div className={"tourController " + (!!task ? "open" : "")}>
      {!task ? (
        <>
          <SpeakingCup>
            "Hallo, ich bin Jana - Wie darf ich Dir heute mit Deinem{" "}
            <b>Esperto Cafe latte</b> helfen?"
          </SpeakingCup>
          <div className="topShadow">
            <div className="innerShadow"></div>
          </div>
          <div className="taskList">
            <Spacer h={12}></Spacer>
            {tasks.map((t) => {
              return (
                <Task
                  onClick={() => {
                    ReactGA.event({
                      category: "Task",
                      action: "Select a task: " + t.button,
                      label: t.button,
                    });

                    setSelectedTask(t.id);
                    setSelectedStep(0);
                  }}
                >
                  {t.button}
                </Task>
              );
            })}
            <Spacer h={12}></Spacer>
          </div>
          <div className="bottomShadow">
            <div className="innerShadow"></div>
          </div>
        </>
      ) : (
        <>
          {step && step.cupText ? (
            <SpeakingCup>{step.cupText}</SpeakingCup>
          ) : (
            <SpeakingCup></SpeakingCup>
          )}
          <div className="taskHeadline">
            {task.button}
            <div className="sub">{step.subText}</div>
          </div>

          <div className="flex1"></div>
          <TaskButtons></TaskButtons>
        </>
      )}
    </div>
  );
}

function TaskButtons() {
  let { selectedStep, task, setSelectedStep, setSelectedTask } = useTour();
  return (
    <div className="taskButtons">
      <div
        className="stepButton"
        onClick={() => {
          setSelectedTask(-1);
        }}
      >
        <FaTimes></FaTimes>
      </div>

      <Spacer w={24}></Spacer>
      <div
        className="stepButton"
        onClick={() => {
          selectedStep > 0 && setSelectedStep(selectedStep - 1);
          selectedStep > 0 &&
            ReactGA.event({
              category: "Task",
              action:
                "Select a step: " + task.button + "," + (selectedStep - 1),
            });
        }}
      >
        <FaChevronLeft></FaChevronLeft>
      </div>

      <Spacer w={24}></Spacer>
      {selectedStep === task.steps.length - 1 ? (
        <div
          className="stepButton"
          onClick={() => {
            setSelectedTask(-1);
          }}
        >
          <FaCheck></FaCheck>
        </div>
      ) : (
        <div
          className="stepButton"
          onClick={() => {
            selectedStep < task.steps.length - 1 &&
              setSelectedStep(selectedStep + 1);
            selectedStep < task.steps.length - 1 &&
              ReactGA.event({
                category: "Task",
                action:
                  "Select a step: " + task.button + "," + (selectedStep + 1),
              });
          }}
        >
          <FaChevronRight></FaChevronRight>
        </div>
      )}
    </div>
  );
}

function Task({ children, onClick, limitHeight, close }) {
  return (
    <div
      onClick={onClick}
      className={"task " + (limitHeight ? "limitHeight" : "")}
    >
      {children}
      <div className="chevron">
        {close ? <FaTimes></FaTimes> : <FaChevronRight></FaChevronRight>}
      </div>
    </div>
  );
}

function SpeakingCup({ children }) {
  return (
    <div className="speakingCup">
      <div className="speechBubble">
        „{children}“<div className="arrowBottom"></div>
      </div>
      <img height={40} alt="tasse" src="./bean.png" className="bean"></img>
    </div>
  );
}
