import { useFrame, useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useRef } from "react";
import "./factoryCanvas.scss";
import * as THREE from "three";

export default function Controller({
  target = [0, 0, 0],
  zoom = 3.5,
  targetTheta = Math.PI / 2 + 0.4,
  targetPhi = (Math.PI / 4) * -1 + 0.2,
  targetString = "0,0,0",
  debug = false,
}) {
  if (debug) {
    target = [0, 0, 0];
  }
  let targetCube = useRef();
  const gl = useThree(({ gl }) => gl);
  const camera = useThree(({ camera }) => camera);

  let data = useMemo(() => {
    return {
      currentPosition: new THREE.Vector3(6, 6, 6),
      currentTarget: new THREE.Vector3(0, 0, 0),
      targetOffset: new THREE.Vector3(0, 0, 0),
      currentZoom: 20,
      dragStart: null,
      panStart: null,
      pinchStart: null,
      lastMouse: null,
      radius: 50,
      theta: 2.21,
      phi: 0.0,
      orbitVelocity: new THREE.Vector2(0, 0),
      zoomVelocity: 0,
    };
  }, []);
  useEffect(() => {
    data.targetOffset = new THREE.Vector3(0, 0, 0);
  }, [targetString, data]);

  const pointerdown = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (data.pinchStart) {
        return;
      }
      if (e.touches && e.touches.length > 1) {
        return;
      }
      let pos = { clientX: e.clientX, clientY: e.clientY };
      if (e.touches) {
        pos.clientX = e.touches[0].clientX;
        pos.clientY = e.touches[0].clientY;
      }
      if (e.button && (e.button === 2 || e.button === 1)) {
        data.panStart = new THREE.Vector2(pos.clientX, pos.clientY);
      } else {
        data.dragStart = new THREE.Vector2(pos.clientX, pos.clientY);
      }
    },
    [data]
  );

  const pointermove = useCallback(
    (e) => {
      if (data.pinchStart) {
        return;
      }
      if (e.touches && e.touches.length > 1) {
        return;
      }
      let pos = { clientX: e.clientX, clientY: e.clientY };
      if (e.touches) {
        pos.clientX = e.touches[0].clientX;
        pos.clientY = e.touches[0].clientY;
      }
      if (data.dragStart) {
        let currentDrag = new THREE.Vector2(pos.clientX, pos.clientY);
        let delta = currentDrag.clone().sub(data.dragStart);
        data.orbitVelocity.add(delta.multiplyScalar(3));

        data.dragStart = currentDrag;
      }
      if (data.panStart) {
        let currentDrag = new THREE.Vector2(pos.clientX, pos.clientY);
        let delta = currentDrag.clone().sub(data.panStart).multiplyScalar(0.02);
        delta.rotateAround(new THREE.Vector2(0, 0), data.phi + Math.PI / 2);
        let offset = new THREE.Vector3(delta.x, 0, delta.y);

        data.targetOffset.add(offset);
        data.panStart = currentDrag;
      }
    },
    [data]
  );

  const touchmove = useCallback(
    (e) => {
      if (e.touches && e.touches.length === 1) {
        pointermove(e);
      }
      if (e.touches && e.touches.length > 1) {
        let f1 = new THREE.Vector2(e.touches[0].clientX, e.touches[0].clientY);
        let f2 = new THREE.Vector2(e.touches[1].clientX, e.touches[1].clientY);
        let distance = f1.distanceTo(f2);
        let totalDistance = distance - data.pinchStart;
        data.zoomVelocity = totalDistance * 0.01;
        data.pinchStart = distance;
      }
    },
    [data, pointermove]
  );
  const touchstart = useCallback(
    (e) => {
      if (e.touches && e.touches.length === 1) {
        pointerdown(e);
      }

      if (e.touches && e.touches.length > 1) {
        //pinch to zoom

        let f1 = new THREE.Vector2(e.touches[0].clientX, e.touches[0].clientY);
        let f2 = new THREE.Vector2(e.touches[1].clientX, e.touches[1].clientY);
        let distance = f1.distanceTo(f2);
        data.pinchStart = distance;
      }
    },
    [data, pointerdown]
  );
  const pointerup = useCallback(
    (e) => {
      data.dragStart = null;
      data.pinchStart = null;
      data.panStart = null;
    },
    [data]
  );
  const wheel = useCallback(
    (e) => {
      if (e.deltaY > 0) {
        data.zoomVelocity = -0.04;
        if (debug) {
          data.zoomVelocity = -0.3;
        }
      } else {
        data.zoomVelocity = 0.04;
        if (debug) {
          data.zoomVelocity = 0.3;
        }
      }
    },
    [data, debug]
  );

  const getOrbitPosition = () => {
    let pos = new THREE.Vector3();

    // Turn back into Cartesian coordinates
    pos.x = data.radius * Math.sin(data.theta) * Math.cos(data.phi);
    pos.y = data.radius * Math.sin(data.theta) * Math.sin(data.phi);
    pos.z = data.radius * Math.cos(data.theta);

    var quat = new THREE.Quaternion().setFromUnitVectors(
      camera.up,
      new THREE.Vector3(0, 0, 1)
    );
    pos.applyQuaternion(quat);
    pos.add(data.currentTarget.clone());
    return pos;
  };

  useEffect(() => {
    //subscribe
    gl.domElement.addEventListener("pointerdown", pointerdown, true);
    gl.domElement.addEventListener("touchstart", touchstart);
    gl.domElement.addEventListener("touchmove", touchmove);
    gl.domElement.addEventListener("touchend", pointerup);
    gl.domElement.addEventListener("pointermove", pointermove);
    gl.domElement.addEventListener("pointerup", pointerup);
    gl.domElement.addEventListener("wheel", wheel);
    return () => {
      //unsubscribe
      gl.domElement.removeEventListener("wheel", wheel);
      gl.domElement.removeEventListener("pointerdown", pointerdown);
      gl.domElement.removeEventListener("pointerup", pointerup);
      gl.domElement.removeEventListener("pointermove", pointermove);
      gl.domElement.removeEventListener("touchstart", touchstart);
      gl.domElement.removeEventListener("touchmove", touchmove);
      gl.domElement.removeEventListener("touchend", pointerup);
    };
  }, [
    gl.domElement,
    pointerdown,
    pointerup,
    pointermove,
    wheel,
    touchstart,
    touchmove,
  ]);

  useFrame(({ mouse }, delta) => {
    // move current Target closer to targetv
    let targetv = new THREE.Vector3(target[0], target[1], target[2]).add(
      data.targetOffset
    );
    delta *= 10;
    let decay = 0.02;
    let radPerPixel = Math.PI / 200000;
    if (debug) {
      decay = 0.5;
      radPerPixel = Math.PI / 2000;
    }
    let targetDelta = targetv.clone().sub(data.currentTarget);
    data.currentTarget.add(
      targetDelta.multiplyScalar(data.panStart ? 0.1 : 0.02)
    );
    var deltaPhi = radPerPixel * -data.orbitVelocity.x,
      deltaTheta = radPerPixel * data.orbitVelocity.y;
    // Subtract deltaTheta and deltaPhi
    data.theta = Math.min(Math.max(data.theta + deltaTheta, 0), Math.PI);
    data.phi -= deltaPhi;
    data.orbitVelocity.multiplyScalar(1 - decay);
    data.currentZoom += data.zoomVelocity;
    data.zoomVelocity = data.zoomVelocity * (1 - decay);

    if (!data.pinchStart) {
      camera.position.copy(getOrbitPosition());
      camera.lookAt(data.currentTarget);
      if (!debug) {
        data.currentZoom += (zoom - data.currentZoom) * decay * 4 * delta;
      }
    }
    camera.zoom = data.currentZoom;
    camera.updateProjectionMatrix();

    if (!data.dragStart && !debug) {
      data.phi += (data.phi - targetPhi) * -decay;
      data.theta += (data.theta - targetTheta) * -decay;
    }

    if (debug && targetCube && targetCube.current) {
      targetCube.current.position.copy(data.currentTarget);

      
    }
  });

  return (
    debug && (
      <>
        <mesh
          ref={targetCube}
          onPointerEnter={() => {
            console.log("a");
            let debugText = `target: [${data.currentTarget.x.toFixed(
              2
            )},${data.currentTarget.y.toFixed(
              2
            )},${data.currentTarget.z.toFixed(2)}],
theta: ${data.theta},
pi: ${data.phi},
zoom: ${data.currentZoom}
`;



            navigator.clipboard.writeText(debugText);
          }}
        >
          <boxGeometry args={[1, 1, 1]}></boxGeometry>
          <meshNormalMaterial></meshNormalMaterial>
        </mesh>
      </>
    )
  );
}
