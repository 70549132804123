import React from "react";
import Layout from "../atoms/layout/layout";
import TourViewer from "../atoms/tourViewer/tourViewer";

export default function Tour() {
  return (
    <Layout noMargin>
      <TourViewer></TourViewer>
    </Layout>
  );
}
