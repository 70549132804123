import { useProgress } from "@react-three/drei";
import React, { useEffect, useState } from "react";
import FactoryCanvas from "../factoryCanvas/factoryCanvas";
import TourController from "../tourController/tourController";
import "./tourViewer.scss";
function Loader({ force = false }) {
  let { active, progress } = useProgress();
  console.log(progress);
  return force || active ? (
    <div className="loader">
      <img alt="tschibo logo" className="logo" src="/logo.png"></img>
      <div className="text">
        {active ? (
          progress.toFixed(0) + "%"
        ) : progress === 100 ? (
          "100%"
        ) : (
          <>&nbsp;</>
        )}
      </div>
      <div className="bar">
        <div className="barInner" style={{ width: progress + "%" }}></div>
      </div>
    </div>
  ) : null;
}
export default function TourViewer() {
  let [force, setForce] = useState(true);
  useEffect(() => {
    let tid = setTimeout(() => {
      setForce(false);
    }, 1000);
    return () => {
      clearTimeout(tid);
    };
  }, []);
  return (
    <div className="tourViewer">
      <FactoryCanvas></FactoryCanvas>
      <TourController></TourController>
      <Loader force={force}></Loader>
      {/*<Loader
        containerStyles={{
          background: "radial-gradient(#FDF8EF, #fcedd3)",
          color: "black",
        }}
        dataStyles={{ color: "#444", fontFamily: "grey", fontSize: "110%" }}
        barStyles={{ backgroundColor: "#444" }}
        innerStyles={{ backgroundColor: "#00000000" }}
        dataInterpolation={(p) => `${(p * 100).toFixed(0)}%`} // Text
      ></Loader>*/}
    </div>
  );
}
