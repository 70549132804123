import create from "zustand";

const useTourState = create((set, get) => {
  return {
    tasks: [
      {
        id: 0,
        button: "Entkalken",
        color: "#e3dad1",
        steps: [
          {
            cupText:
              "Schalten Sie die Maschine ggf. mit der Ein-/Aus-Taste aus.",
            boxText: "Schritt 1 von 9",
            subText: "Noch ca. 15 Minuten",

            target: [0.4, 0.0, 0.1],
            theta: 2.8006194188748714,
            phi: 0.17592918185843023,
            zoom: 10.509151182076163,

            animations: {
              blink: ["AniBut1", "AniBut4"],
              open: [],
            },
          },
          {
            cupText:
              "Ziehen Sie den Wassertank nach oben aus der Maschine heraus.",
            boxText: "Schritt 2 von 9",
            subText: "Noch ca. 15 Minuten",

            target: [-0.88, 0.0, -1.85],
            theta: 1.8801327713730738,
            phi: 2.0388936321797764,
            zoom: 1.9255084037780656,

            animations: {
              blink: [],
              open: [],
            },
          },
          {
            cupText:
              "Ziehen Sie den Wassertank nach oben aus der Maschine heraus.",
            boxText: "Schritt 2 von 9",
            subText: "Noch ca. 15 Minuten",

            target: [-0.88, 0.0, -1.85],
            theta: 1.8801327713730738,
            phi: 2.0388936321797764,
            zoom: 1.9255084037780656,

            animations: {
              blink: [],
              open: ["AniTank"],
            },
          },
          {
            cupText:
              "Füllen Sie einen handelsüblichen flüssigen Entkalker für Kaffee- und Espressomaschinen in den leeren Wassertank. Geben Sie Wasser bis zur MAX-Markierung hinzu. Beachten Sie die Angaben des Herstellers.",
            boxText: "Schritt 3 von 9",
            subText: "Noch ca. 13 Minuten",
            target: [-1.11, 0.0, 0.11],
            theta: 2.5147344873982154,
            phi: 2.786592683734135,
            zoom: 3.725529115933642,

            animations: {
              blink: [],
              open: ["AniTank"],
            },
          },
          {
            cupText: "Setzen Sie den Wassertank wieder in die Maschine ein.",
            boxText: "Schritt 3 von 9",
            subText: "Noch ca. 13 Minuten",

            target: [-0.88, 0.0, -1.85],
            theta: 1.8801327713730738,
            phi: 2.0388936321797764,
            zoom: 1.9255084037780656,
            animations: {
              blink: [],
              open: [],
            },
          },
          {
            cupText:
              "Stellen Sie einen leeren Behälter (mind. 500ml Fassungsvermögen) unter den Kaffeeauslauf und die Auzfschaumdüse. Entfernen Sie dazu ggf. die Abtropfschale.",
            boxText: "Schritt 4 von 9",
            subText: "Noch ca. 11 Minuten",

            target: [0.92, 0.0, -0.02],
            theta: 2.141858437490753,
            phi: 0.07225663103256626,
            zoom: 5.914923538109554,

            animations: {
              blink: [],
              open: [],
            },
          },
          {
            cupText:
              "Drücken Sie die Ein-/Aus-Taste, um die Maschine einzuschalten.",
            boxText: "Schritt 5 von 9",
            subText: "Noch ca. 8 Minuten",

            target: [0.4, 0.0, 0.1],
            theta: 2.8006194188748714,
            phi: 0.17592918185843023,
            zoom: 10.509151182076163,

            animations: {
              blink: ["AniBut1", "AniBut4"],
              open: [],
            },
          },
          {
            cupText:
              "Der Entkalkungsvorgang beginnt. Der Entkalkungsvorgang dauert ca. 15 Minuten. Die Maschine pumpt in 40-Sekunden-Abständen mehrmals Entkalkerlösung in die Leitungen. Dabei laufen jedes Mal ca. 50 ml in den untergestellten Behälter.",
            boxText: "Schritt 9 von 9",
            subText: "Fertig!",
            target: [0.0, 0.0, 0.0],
            theta: 2.1669911789232534,
            phi: 0.6251769381836458,
            zoom: 4.049286084031076,

            animations: {
              blink: [],
              open: [],
            },
          },
        ],
      },
      {
        id: 1,
        button: "Restwasserschale reinigen",
        color: "#e3dad1",
        steps: [
          {
            cupText: "Nehmen Sie die Abtropfschale ab.",
            boxText: "Schritt 1 von 5",
            subText: "Ca. 5 Minuten",
            target: [3.66, 0.0, 0.47],
            theta: 1.8074728312248594,
            phi: 1.1927575669091957,
            zoom: 3.4996931761940457,

            animations: {
              blink: [],
              open: ["AniFront1"],
            },
          },

          {
            cupText:
              "Ziehen Sie die Restwasserschale mit dem Kaffeesatzbehälter heraus.",
            boxText: "Schritt 2 von 5",
            subText: "Noch ca. 3 Minuten",
            target: [4.19, 0.0, 0.93],
            theta: 2.589729401968717,
            phi: 0.39793462555097814,
            zoom: 3.4996931761940457,

            animations: {
              blink: [],
              open: ["AniFront1", "AniFront2"],
            },
          },

          {
            cupText:
              "Leeren Sie den Kaffeesatzbehälter, die Restwasser- und ggf. die Abtropfschale.",
            boxText: "Schritt 3 von 5",
            subText: "Noch ca. 2 Minuten",
            target: [2.7, 0.0, -0.46],
            theta: 1.970835648242781,
            phi: 1.299571712835026,
            zoom: 3.4968649079389915,

            animations: {
              blink: [],
              open: ["AniFront1", "AniFront2", "AniFront3"],
            },
          },

          {
            cupText:
              "Waschen Sie alle Einzelteile unter Zusatz eines Geschirrspülmittels ab. Trocknen Sie die Teile gut ab. Diese Teile sind nicht spülmaschinengeeignet.",
            boxText: "Schritt 4 von 5",
            subText: "Fast geschafft",

            target: [2.7, 0.0, -0.46],
            theta: 1.970835648242781,
            phi: 1.299571712835026,
            zoom: 3.4968649079389915,
            animations: {
              blink: [],
              open: ["AniFront1", "AniFront2", "AniFront3"],
            },
          },

          {
            cupText: "Setzen Sie alle Teile wieder in die Maschine ein.",
            boxText: "Schritt 5 von 5",
            subText: "Fertig",

            theta: 2.5,
            phi: 0,
            zoom: 3,

            target: [0, 0, 0],
            animations: {
              blink: [],
              open: [],
            },
          },
        ],
      },
      {
        id: 2,
        button: "Brühgruppe reinigen",
        color: "#e3dad1",
        steps: [
          {
            cupText:
              "Drücken Sie wie abgebildet im unteren Bereich auf die Abdeckung der Brühgruppe.",
            boxText: "Schritt 1 von 8",
            subText: "Ca. 4 Minuten",

            target: [-0.52, 0.0, 0.06],
            theta: 1.7428761701909692,
            phi: 1.0178760206728616,
            zoom: 5.7000000000000295,

            animations: {
              blink: [],
              open: [],
            },
          },
          {
            cupText:
              "Drücken Sie wie abgebildet im unteren Bereich auf die Abdeckung der Brühgruppe.",
            boxText: "Schritt 1 von 8",
            subText: "Ca. 4 Minuten",

            target: [-0.59, 0.0, 0.46],
            theta: 1.6423452369201121,
            phi: 1.8849555505214828,
            zoom: 6.392160066548631,

            animations: {
              blink: [],
              open: ["AniSide1"],
            },
          },

          {
            cupText:
              "Halten Sie die beiden orange-farbenden Tasten fest gedrückt und ziehen Sie die Brühgruppe gerade aus der Maschine heraus.",
            boxText: "Schritt 3 von 8",
            subText: "Noch ca. 4 Minuten",

            target: [-0.59, 0.0, 0.46],
            theta: 1.6706195394522858,
            phi: 1.8503980729643876,
            zoom: 6.787500000073574,

            animations: {
              blink: [],
              open: ["AniSide1", "AniSide2", "AniSide3"],
            },
          },
          {
            cupText:
              "Reinigen Sie die Brühgruppe unter fließendem, warmen Wasser.",
            boxText: "Schritt 4 von 8",
            subText: "Noch ca. 3 Minuten",
            target: [-0.29, 0.0, -0.08],
            theta: 2.028761102014421,
            phi: 1.2849113952002034,
            zoom: 4.757149864939367,

            animations: {
              blink: [],
              open: ["AniSide1", "AniSide2", "AniSide3"],
            },
          },
          {
            cupText:
              "Drücken Sie die Brühgruppe mit etwas Kraft wieder in die Maschine hinein, ohne die Orangefarbenden Tasten zu drücken. Die Brühgruppe muss hörbar und spürbar einrasten.",
            boxText: "Schritt 5 von 8",
            subText: "Noch ca. 2 Minuten",

            theta: 2.5,
            phi: 0,
            zoom: 3,

            target: [0, 0, 0],
            animations: {
              blink: [],
              open: [],
            },
          },
        ],
      },
      {
        id: 3,
        button: "Mahlgrad einstellen",
        color: "#e3dad1",
        steps: [
          {
            cupText: "Heben Sie die obere Abdeckung von der Maschine.",
            boxText: "Schritt 1 von 3",
            subText: "Ca. 2 Minuten",

            target: [-0.66, 0.0, -0.11],
            theta: 3.141592653589793,
            phi: 0.41468810400447315,
            zoom: 4.6477621726876,

            animations: {
              blink: [],
              open: ["AniTop1"],
            },
          },

          {
            cupText: "Stellen Sie den Mahlgrad auf die gewünschte Feinheit.",
            boxText: "Schritt 2 von 3",
            subText: "Noch ca. 4 Minuten",

            target: [0.28, 0.0, -2.02],
            theta: 2.1123283604574423,
            phi: 1.947392619874594,
            zoom: 7.837597339618747,

            animations: {
              blink: ["AniMahlgrad"],
              open: ["AniTop1"],
            },
          },

          {
            cupText: "Fügen Sie den Deckel wieder ein.",
            boxText: "Schritt 3 von 3",
            subText: "Fertig",

            theta: 2.5,
            phi: 0,
            zoom: 3,

            target: [0, 0, 0],
            animations: {
              blink: [],
              open: [],
            },
          },
        ],
      },
    ],
    selectedTask: -1,
    selectedStep: -1,
    setSelectedTask: (selectedTask) => set({ selectedTask }),
    setSelectedStep: (selectedStep) => set({ selectedStep }),
  };
});

export default useTourState;
